import * as React from "react";
import { useEffect, useRef } from "react";

import { Link } from "react-router-dom";

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import { gsap, TweenMax, TimelineMax, TweenLite, Power2 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

import "./home.scss";

function Home() {
  let body = useRef(null);
  let setTextTransition = gsap.timeline();
  let bigTextItem,
    subtitle,
    elementI,
    elementP,
    elementLine,
    elementS,
    elementC,
    elementCH = useRef(null);

  useEffect(() => {
    function myFunction() {
      var scrollpercent =
        (document.body.scrollTop + document.documentElement.scrollTop) /
        (document.documentElement.scrollHeight -
          document.documentElement.clientHeight);

      var draw = length * scrollpercent;

      // Reverse the drawing (when scrolling upwards)
      triangle.style.strokeDashoffset = length - draw;
    }

    // Get the id of the <path> element and the length of <path>
    var triangle = document.getElementById("triangle");
    var length = triangle.getTotalLength();

    // The start position of the drawing
    triangle.style.strokeDasharray = length;

    // Hide the triangle by offsetting dash. Remove this line to show the triangle before scroll draw
    triangle.style.strokeDashoffset = length;

    // Find scroll percentage on scroll (using cross-browser properties), and offset dash same amount as percentage scrolled
    window.addEventListener("scroll", myFunction);

    setTextTransition
      .to(bigTextItem, {
        y: -15,
        opacity: 1,
        delay: 0.4,
        ease: Power2.out,
      })
      .to(subtitle, {
        y: 15,
        opacity: 1,
        delay: 0.2,
        ease: Power2.out,
      });


    var triggerOffset = document.documentElement.clientHeight / 2;
    // var triggerOffset = 500;
    var totalHeight = 1440;
    var sceneStart = 0;
    var sceneEnd = totalHeight;
    var duration = 500;

    // var subtraction = totalHeight - elementI.current.getBoundingClientRect().y;


    var requestId = null;

    TweenLite.set(".timeline-trigger", {
      top: triggerOffset
    });

    TweenLite.set(".start-trigger", {
      top: sceneStart
    });

    TweenLite.set(".end-trigger", {
      top: sceneStart
    });


    // Scroll Magic for words coming together
    var tl = new TimelineMax({ paused: true })
      .to(".element-i", { opacity: 0 })
      .to(".element-i", 500, { y: 200, x: 850, fontSize: 36 })
      .to(".element-i", 800, { opacity: 1 })
      // .to(".element-i", 300, { y: 800, x: 800, opacity: 1 })
      .to(".element-i", 200, { y: 950, x: 520, opacity: 1, fontSize: 20 })

    var tl1 = new TimelineMax({ paused: true })
      .to(".element-c", { opacity: 0 })
      .to(".element-c", 500, { y: 400, x: 450, fontSize: 44 })
      .to(".element-c", 800, { opacity: 1 })
      // .to(".element-c", 100, { y: 950, x: 600, fontSize: 16 })
      .to(".element-c", 100, { y: 950, x: 520, fontSize: 20 })

    var tl2 = new TimelineMax({ paused: true })
      .to(".element-s", { opacity: 0 })
      .to(".element-s", 500, { y: 600, x: -100, fontSize: 36 })
      .to(".element-s", 800, { opacity: 1 })
      // .to(".element-s", 100, { y: 950, x: 250, fontSize: 16 })
      .to(".element-s", 100, { y: 950, x: 520, fontSize: 20 })

    var tl3 = new TimelineMax({ paused: true })
      .to(".element-p", { opacity: 0 })
      .to(".element-p", 500, { y: 650, x: 120, fontSize: 22 })
      .to(".element-p", 800, { opacity: 1 })
      // .to(".element-p", 100, { y: 950, x: 250, fontSize: 16 })
      .to(".element-p", 100, { y: 950, x: 520, fontSize: 20 })

    var tl4 = new TimelineMax({ paused: false })
      .to(".element-ch", { opacity: 0 })
      .to(".element-ch", 500, { y: 780, x: 0, fontSize: 36 })
      .to(".element-ch", 800, { opacity: 1 })
      // .to(".element-ch", 100, { y: 950, x: 250, fontSize: 16 })
      .to(".element-ch", 100, { y: 950, x: 520, fontSize: 20 })


    // Only update on animation frames
    window.addEventListener("scroll", function () {
      if (!requestId) {
        requestId = requestAnimationFrame(update);
      }
    });

    update();

    // Set timeline time to scrollTop
    function update() {
      tl.time(window.pageYOffset + triggerOffset);
      requestId = null;
      tl1.time(window.pageYOffset + triggerOffset);
      requestId = null;
      tl2.time(window.pageYOffset + triggerOffset);
      requestId = null;
      tl3.time(window.pageYOffset + triggerOffset);
      requestId = null;
      tl4.time(window.pageYOffset + triggerOffset);
      requestId = null;
    }

    TweenMax.to(body, {
      css: {
        opacity: "1",
        pointerEvents: "auto",
      },
    });
    return () => {
      TweenMax.to(body, 0, {
        css: {
          opacity: "0",
          pointerEvents: "none",
        },
      });
    };
  }, [elementI]);

  return (
    <React.Fragment>
      <Header style={{ background: '#E6FDFE' }} />
      <div className="load-container">
        <div className="load-screen" ref={(el) => (screen = el)}></div>
      </div>
      <div className="marker timeline-trigger"></div>
      <div className="marker start-trigger"></div>
      <div className="marker end-trigger"></div>

      <div className="box"></div>

      <div className="Home">
        <div ref={(el) => (body = el)} className="Head" id="homeBigText">
          <p
            id="bigText"
            ref={(el) => {
              bigTextItem = el;
            }}
          >
            THORBEN PFEIL{" "}
          </p>
          <p
            className="subtitle"
            ref={(el) => {
              subtitle = el;
            }}
          >
            A multi-disciplinary innovation consultant, project-shaper,
            mind-bender, and teal-lover.
          </p>
        </div>
        <div className="mobile-tagline" style={{ display: "none" }}>i combine structure & creative chaos</div>

        <div className="elements">
          <div className="grey-box">
            <div className="svg-container">
              <div id="mySVG">
                <svg id="" viewBox="0 0 1280 900">
                  <path
                    d="M627.023 2C627.023 27.6177 629.298 52.2753 589.155 71.9475C566.044 83.2733 544.84 93.2906 532.515 107.635C491.257 155.655 669.143 150.495 717.323 168.859C744.343 179.158 809.462 173.3 842.579 173.3C863.06 173.3 884.768 174.388 902.132 180.279C943.472 194.305 938.412 213.198 946.149 235.476C951.119 249.783 946.136 282.177 909.252 287.342C868.598 293.034 825.348 297.123 801.798 317.319C780.453 335.625 730.002 339.496 693.696 347.455C643.384 358.486 572.516 352.991 525.718 368.868C489.468 381.166 448.722 373.369 411.467 381.715C387.785 387.021 366.099 387.19 357.74 399.48C349.892 411.018 332.534 420.867 329.905 433.105C325.137 455.302 329.299 467.04 341.881 488.619C353.711 508.911 317.411 533.74 292.037 550.319C240.616 583.918 112.304 572.538 79.3943 614.874C67.5065 630.166 67.1044 658.342 81.9836 672.925C101.838 692.385 55.7979 702.761 26.9618 710.04C-22.573 722.546 10.8248 814.519 47.6759 822.728C69.3857 827.564 105.523 824.155 129.238 824.155C165.164 824.155 201.09 824.155 237.016 824.155C309.011 824.155 255.648 850.705 323.77 850.705C435.922 850.705 353.112 911.96 410.394 934.928C445.087 948.838 437.775 945.776 480.813 945.776C536.236 945.776 562.735 925.036 601.537 910.247C617.577 904.133 641.424 913.762 658.177 914.37C685.028 915.345 693.546 926.173 709.315 934.355C735.245 947.81 731.343 935.527 769.5 938.575C802 936.65 836.1 937 872 937C891.83 937 866.89 919.128 861.12 915.594C849.06 908.209 873.447 929.749 879.187 932C890.177 936.307 884.5 934.355 894 938.575C884.5 942.5 887.99 941.189 875.05 944.36C858 948.537 855.5 949.5 871 944.36C877.91 939.702 882.24 942.231 891.5 938.415"
                    fill="none"
                    id="triangle"
                    stroke="black"
                    strokeWidth="1"
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
                <Link to="/projects"><button className="cta">
                  <p style={{ fontFamily: "Helvetica", fontSize: 13 }} > find out how</p>
                </button>
                </Link>
              </div>
              <div className="weird-container">
                <div
                  className="element-i"
                  ref={(el) => {
                    elementI = el;
                  }}
                >
                  <p>i</p>
                </div>
                <div
                  className="element-c"
                  ref={(el) => {
                    elementLine = el;
                  }}
                >
                </div>
                <div
                  className="element-c"
                  ref={(el) => {
                    elementC = el;
                  }}
                >
                  <p>combine</p>
                </div>
                <div
                  className="element-s"
                  ref={(el) => {
                    elementS = el;
                  }}
                >
                  <p>structure</p>
                </div>
                <div
                  className="element-p"
                  ref={(el) => {
                    elementP = el;
                  }}
                >
                  <p>&</p>
                </div>
                <div
                  className="element-ch"
                  ref={(el) => {
                    elementCH = el;
                  }}
                >
                  <p>creative chaos</p>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <Footer style={{ background: '#E6FDFE', zIndex: 1 }} />
    </React.Fragment>
  );
}

export default Home;
