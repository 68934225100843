import './footer.scss'

const Footer = ({ style }) => {
  return (
    <div className='footerWrapper' style={style}>
      <div className='infoLeft'>
        <a href="https://www.linkedin.com/in/thorbenpfeil/" aria-label="LinkedIn" rel="noopener" target="_blank">linkedin</a><br />
      </div>
      <div className='infoRight'>
        <a href="mailto:hello@thorbenpfeil.com" aria-label="Email" rel="noopener" target="_blank">hello@thorbenpfeil.com</a>
      </div>
    </div>
  );
};

export default Footer;
