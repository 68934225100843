import React, { useState, useEffect, useRef } from "react";
import sanityClient from "../../client.js";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import 'react-lazy-load-image-component/src/effects/opacity.css';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import "./projects.scss";

function Projects() {
  const [postData1, setPostData1] = useState(null);

  let singleProject = useRef([]);

  let fadeInTween;

  useEffect(() => {
    sanityClient
      .fetch([
        `*[_type == "post"] | order(publishedAt desc){
        title,
        publishedAt,
        _id,
        link,
        slug,
        description,
        "bannerImage": bannerImage.asset->url,
        }`
      ])
      .then((data1) => setPostData1(data1))
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (window.innerWidth > 737) {

      fadeInTween = gsap.from(singleProject.current, { y: 15, autoAlpha: 0, stagger: 0.4 });

    } else {
      fadeInTween = null;
    }
  })

  
  return (
    <React.Fragment>
      <Header style={{ background: '#F9F9F9', zIndex: 1 }} />

      <div className="Projects">
        <p className="titleProjects">Projects</p>
        <ul className="eachProject">
          {postData1 &&
            postData1.map((post, idx) => (
              <div>
                <Link key={idx} to={post.link}>
                  <li key={idx}>
                    <div className="title-box">
                      <LazyLoadImage 
                        className="banner-image" 
                        src={post.bannerImage} 
                        effect="opacity"
                        alt="single-project-image"
                      />
                      <span className="title" ref={(el) => {
                        singleProject.current[idx] = el;
                      }}>{post.title}</span>

                      <span className="description">{post.description}</span>

                    </div>
                  </li>
                </Link>
              </div>
            ))}
        </ul>
      </div>
      
      <Footer style={{ background: '#F9F9F9', zIndex: 1 }} />

    </React.Fragment>
  );
}

export default Projects;
