import React, { useState, useEffect } from "react";
import sanityClient from "../../client.js";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import { Link } from "react-router-dom";

import "./about.scss";

function About() {

  const [aboutData, setAboutData] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch([
        `*[_type == "aboutPage"] {
          headline,
          "aboutImage": aboutImage.asset->url,
          aboutOne,
          largeHeadline,
          aboutTwo,
          notableExperiences,
          usefulSkills
        }`
      ])
      .then((data) => setAboutData(data) && console.log(data))
      .catch(console.error);
  }, []);

  if (!aboutData) return <div className="loading">Loading...</div>;


  return (
    <div className="About">
      <Header style={{ background: '#F9F9F9', zIndex: 1 }} />
      {aboutData &&
        aboutData.map((about, idx) => (
          <>
            <p className="aboutTitle">About</p>
            <div className="content">
              <p className="aboutText">
                {about.headline}
                {console.log(about)}
              </p>
              <div>
                <p className="aboutText2">
                  {about.aboutOne[0].children[0].text}
                </p>
              </div>
            </div>
            <div className="thorby">
              <LazyLoadImage
                loading="lazy"
                rel="preload"
                alt="thorben-pfeil-profile-image"
                id="thorben"
                effect="opacity"
                src={about.aboutImage} />
            </div>

            <div className="doubleLines">
              {about.largeHeadline}
            </div>
            <div className="content2">

              <Link to="/projects">
                <button className="view">
                  <p style={{ fontFamily: "Helvetica", fontSize: 13 }} >view my work</p>
                </button>
              </Link>
              <p className="aboutText3">
                {about.aboutTwo[0].children[0].text}
              </p>

              <div className="aboutText4">
                <ul>Notable Experiences
                  {about.notableExperiences.map((val) => <li>{val}</li>)}
                </ul>
                <ul>Useful Skills
                  {about.usefulSkills.map((val) => <li>{val}</li>)}
                </ul>
              </div>
            </div>
          </>
        ))}
      <Footer style={{ background: '#F9F9F9', zIndex: 1 }} />
    </div>
  );
};

export default About;