import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Projects from "./pages/Projects/Projects";
import SingleProject from "./pages/SingleProject/SingleProject";
import AnimatedCursor from "react-animated-cursor";

import "./App.css";
import "./assets/style/global.scss";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/project/:slug">
            <SingleProject />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <AnimatedCursor
          innerSize={8}
          outerSize={8}
          color="0, 0, 0"
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={5}
        />
      </Router>
    </div>
  );
}

export default App;
