import { Link } from "react-router-dom";

import logo from "../../assets/images/logo-black.svg"

import "./header.scss";

const Header = ({ style }) => {
  return (
    <div className="header" style={style}>
      <div className="headerWrapper">
        <div className="tabs">
          <Link to="/projects"><span style={{ height: "50" }}>projects</span></Link>
          <Link to="/about"><span style={{ height: "50" }}>about</span></Link>
        </div>
        <div className="menu">
          <Link to="/">
            <img src={logo} alt="thorben logo" className="logo" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
