import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../../client.js";
import arrowUp from '../../assets/images/arrowUp.png';

import { Controller, Scene } from "react-scrollmagic";

import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";

import "@brainhubeu/react-carousel/lib/style.css";

import { gsap, Power2 } from "gsap";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


import "./singleproject.scss";

const SingleProject = () => {
  const [singlePost, setSinglePost] = useState(null);
  const [value, setValue] = useState(0);

  const handleDragStart = (e) => e.preventDefault();

  let singleProjectTransition = gsap.timeline();
  let singleTitleItem = useRef(null);

  const { slug } = useParams();
  const introRef = useRef(null);
  const solutionRef = useRef(null);
  const solutionValueRef = useRef(null);
  const processRef = useRef(null);
  const roleRef = useRef(null);
  const topRef = useRef(null);

  const introScroll = () =>
    introRef.current.scrollIntoView({ behavior: "smooth", block: "center" });

  const solutionScroll = () =>
    solutionRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  const solutionValueScroll = () =>
    solutionValueRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  const processScroll = () =>
    processRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  const roleScroll = () =>
    roleRef.current.scrollIntoView({ behavior: "smooth", block: "center" });

  const backToTopScroll = () =>
    topRef.current.scrollIntoView({ behavior: "smooth", block: "center" });

  const onChange = (value) => {
    setValue(value);
  };

  useEffect(() => {

    singleProjectTransition
      .to(singleTitleItem.current, {
        y: -15,
        opacity: 1,
        delay: 0.4,
        ease: Power2.out,
      })

    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
        title,
        _id,
        slug,
        "imageUrl": mainImage.asset->url,
        description,
        summary,
        "sImageUrl1": sImage1.asset->url,
        "sImageUrl2": sImage2.asset->url,
        "sImageUrl3": sImage3.asset->url,
        "sImageUrl4": sImage4.asset->url,
        "sImageUrl5": sImage5.asset->url,
        "sImageUrl6": sImage6.asset->url,
        "wImageUrl1": wImage1.asset->url,
        "wImageUrl2": wImage2.asset->url,
        "wImageUrl3": wImage3.asset->url,
        "pImageUrl1": pImage1.asset->url,
        "pImageUrl2": pImage2.asset->url,
        "pImageUrl3": pImage3.asset->url,
        "pImageUrl4": pImage4.asset->url,
        "pImageUrl5": pImage5.asset->url,
        "pImageUrl6": pImage6.asset->url,
        "rImageUrl1": rImage1.asset->url,
        intro,
        solution,
        solutionValue,
        there,
        myRole,
        initiatedBy,
        "iImageUrl1": iImage1.asset->url,
        "iImageUrl2": iImage2.asset->url,
        "iImageUrl3": iImage3.asset->url
        }`
      )
      .then((data) => setSinglePost(data))
      .catch(console.error);

  }, [slug]);

  if (!singlePost) return <div className="loading">Loading...</div>;

  const arrayObject = () => {
    if (!singlePost[0].there) {
      return " ";
    } else {
      const gettingThere = singlePost[0].there.map((children, idx) => (
        <li key={idx}>{children.children[0].text}</li>
      ));
      return gettingThere;
    }
  };

  const movieInsteadOfMainImage = () => {
    if (singlePost[0].slug.current === "moderna-museet") {
      return (
        <>
          <iframe
            className="modernaMovie"
            width="800px"
            height="400px"
            src="https://www.youtube.com/embed/3aZT-WzC5k4"
            title="Moderna Museum"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowFullScreen
          ></iframe>
        </>
      );
    }
  };

  let solutionImageAssets = [
    singlePost[0].sImageUrl1,
    singlePost[0].sImageUrl2,
    singlePost[0].sImageUrl3,
    singlePost[0].sImageUrl4,
    singlePost[0].sImageUrl5,
    singlePost[0].sImageUrl6,
  ]

  let solutionImages = [
    <img src={solutionImageAssets[0]} className="sImg1" onDragStart={handleDragStart} role="presentation" />,
    <img src={solutionImageAssets[1]} className="sImg1" onDragStart={handleDragStart} role="presentation" />,
    <img src={solutionImageAssets[2]} className="sImg1" onDragStart={handleDragStart} role="presentation" />,
    <img src={solutionImageAssets[3]} className="sImg1" onDragStart={handleDragStart} role="presentation" />,
    <img src={solutionImageAssets[4]} className="sImg1" onDragStart={handleDragStart} role="presentation" />,
    <img src={solutionImageAssets[5]} className="sImg1" onDragStart={handleDragStart} role="presentation" />,
  ]

  const solutionResults = solutionImages.filter(element => {
    return element.props.src !== null;

  });


  let whyImageAssets = [
    singlePost[0].wImageUrl1,
    singlePost[0].wImageUrl2,
    singlePost[0].wImageUrl3,
  ]

  let whyImages = [
    <img src={whyImageAssets[0]} className="wImg1" onDragStart={handleDragStart} role="presentation" />,
    <img src={whyImageAssets[1]} className="wImg1" onDragStart={handleDragStart} role="presentation" />,
    <img src={whyImageAssets[2]} className="wImg1" onDragStart={handleDragStart} role="presentation" />,
  ]

  const whyResults = whyImages.filter(element => {
    return element.props.src !== null;
  });


  let processImageAssets = [
    singlePost[0].sImageUrl1,
    singlePost[0].sImageUrl2,
    singlePost[0].sImageUrl3,
    singlePost[0].sImageUrl4,
    singlePost[0].sImageUrl5,
    singlePost[0].sImageUrl6,
  ]

  let processImages = [
    <img src={processImageAssets[0]} className="pImg1" onDragStart={handleDragStart} role="presentation" />,
    <img src={processImageAssets[1]} className="pImg1" onDragStart={handleDragStart} role="presentation" />,
    <img src={processImageAssets[2]} className="pImg1" onDragStart={handleDragStart} role="presentation" />,
    <img src={processImageAssets[3]} className="pImg1" onDragStart={handleDragStart} role="presentation" />,
    <img src={processImageAssets[4]} className="pImg1" onDragStart={handleDragStart} role="presentation" />,
    <img src={processImageAssets[5]} className="pImg1" onDragStart={handleDragStart} role="presentation" />,
  ]

  const processResults = processImages.filter(element => {
    return element.props.src !== null;
  });

  return (
    <>
      <Header style={{ background: '#F8FFFF', zIndex: 2 }} />
      <div className="SingleProject">
        <div className="singleProjectMenu">
          <button onClick={introScroll} className="projMenuButton">
            <h4 className="projMenuText">Intro</h4>
          </button>
          <button onClick={solutionScroll} className="projMenuButton">
            <h4 className="projMenuText">Solution</h4>
          </button>
          <button onClick={solutionValueScroll} className="projMenuButton">
            <h4 className="projMenuText">Solution Value</h4>
          </button>
          <button onClick={processScroll} className="projMenuButton">
            <h4 className="projMenuText">Process</h4>
          </button>
          <button onClick={roleScroll} className="projMenuButton">
            <h4 className="projMenuText">My Role</h4>
          </button>
        </div>
        <Controller>
          <div className="title-box-single" ref={topRef}>
            <Scene duration={250} pin>
              <h1 className="titleSingle">{singlePost[0].title}</h1>
            </Scene>
            <p className="descSingle">{singlePost[0].description}</p>
          </div>

          <div className="intro" ref={introRef}>
            <h1>Intro</h1>
            <p>{singlePost[0].intro}</p>
          </div>

          <div>
            <img src={singlePost[0].imageUrl} width="80%" className="mainImage" />
            {movieInsteadOfMainImage()}
          </div>

          <div className="solution-thing" ref={solutionRef}>
            <h1 className="solution-title">Solution</h1>
            <div

            >
              <p className="solution-text">{singlePost[0].solution}</p>

              <AliceCarousel mouseTracking items={solutionResults} />

            </div>
          </div>
          <div className="solutionValue" ref={solutionValueRef}>
            <h1>Why is this solution important?</h1>
            <p>{singlePost[0].solutionValue}</p>
          </div>

          <div className="why-slideshow">

            <AliceCarousel mouseTracking items={whyResults} />
          </div>

          <div className="process" ref={processRef}>
            <h1 className="process-title">How did we get there?</h1>
            <div
              className="process-slideshow"
            >
              <p className="process-text">{arrayObject()[0].props.children}</p>
              <AliceCarousel mouseTracking items={processResults} />

            </div>
          </div>

          <div className="role-thing" ref={roleRef}>
            <h1>My Role</h1>
            <div className="role-div">
              <p className="role-text">{singlePost[0].myRole}</p>
              {singlePost[0].rImageUrl1 !== null && (
                <img
                  src={singlePost[0].rImageUrl1}
                  width="30%"
                  className="role-img"
                />
              )}
            </div>
          </div>

          <div className="initiated-slideshow">
            <img
              src={singlePost[0].iImageUrl1}
              width="100px"
              className="imgIni"
            />
            <img
              src={singlePost[0].iImageUrl2}
              width="100px"
              className="imgIni"
            />
            {singlePost[0].iImageUrl3 !== null && (
              <img
                src={singlePost[0].iImageUrl3}
                width="100px"
                className="imgIni"
              />
            )}
          </div>

          <div className="initiated">
            <p>{singlePost[0].initiatedBy}</p>
          </div>

          <div className="top">
            <button onClick={backToTopScroll} className="top-button">
              <img className="arrowUp" src={arrowUp} />
            </button>
          </div>
        </Controller>
      </div>

      <Footer style={{ background: '#F8FFFF', zIndex: 1 }} />
    </>
  );
};

export default SingleProject;
